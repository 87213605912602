.notification {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;

    &::-webkit-scrollbar {
        display: none;
    }

    .contentTitle {
        margin-bottom: 40px;
        color: black;
    }

    .content {
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        height: inherit;

        @media (max-height : 800px) {
            height: fit-content;

        }


        .row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 20px 30px;

            h2 {
                color: black;
                margin-right: 40px;
                text-align: center;
                margin: 0 0 20px;
            }


            .updateInput {
                width: 100%;
                margin: 0px 0;
                padding: 0 20px;
                border-radius: 50px;
                background-color: white;
                height: 65px;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #474F61;

                .input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 10px 0 10px;

                    color: black;
                    font-size: 16px;
                    border: none;
                    outline: none;
                    align-items: center;
                    display: flex;

                    &::-webkit-inner-spin-button,
                    ::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                }
            }
        }

        .submit {
            width: 100%;
            border-radius: 50px;
            // background-image: linear-gradient(90deg, #107bff, #1eb8ff);
            background-color: #6268F3;
            border: none;
            color: white;
            font-size: 1.6rem;
            min-height: 70px;
            max-height: 70px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 350px;
            margin: auto;

            @media (max-height: 900px) {
                margin: 60px auto 30px;
            }
        }
    }


}
.businessDetail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .loadingContainer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        h1.error {
            color: rgb(96, 94, 94);
        }

    }

    .topContainer {
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 20px;

        h1.back {

            font-size: 22px;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            cursor: pointer;

            span.arrow {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }
        }


    }

    .detailContainer {
        height: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;

        .info {
            width: 100%;
            height: 40%;

            .row {

                height: 100%;

                .col {
                    height: 100%;

                    &.imageContainer {

                        .businessImage {
                            margin: auto;
                            justify-content: center;
                            display: flex;
                            align-items: center;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }


                    &.businessDetailContainer {
                        width: 100%;
                        height: 100%;
                        padding: 10px 20px;
                        overflow: auto;

                        .businessHeaderContainer {
                            display: flex;
                            flex-direction: row;
                            align-items: baseline;

                            h1.businessName {
                                // margin-top: 100vh;
                                margin: 0;
                                padding-right: 10px;
                            }

                            button.status {
                                margin-left: auto;
                                color: white;
                                cursor: pointer;
                                border: none;
                                font-size: 15px;
                                padding: 7px 50px;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &.active {
                                    background-color: #3ABF51;
                                }

                                &.deactive {
                                    background-color: #E35C5C;
                                }
                            }

                        }


                        p.businessEmail {
                            margin: 0;
                            color: rgb(96, 94, 94);
                            font-size: 15px;

                        }

                        p.businessDescription {
                            color: rgb(96, 94, 94);
                            margin: 10px 0;
                        }

                        &::-webkit-scrollbar {
                            width: 5px;
                            display: block;
                            background-color: rgb(214, 212, 212);

                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: grey;
                            border-radius: 6px;
                            border: 3px solid grey;
                        }
                    }


                }

            }
        }

        .multiTabs {
            width: 100%;
            height: 60%;
            display: flex;
            flex-direction: column;
            padding: 10px 0;


            .tabsHeaderContainer {
                display: flex;
                flex-direction: row;

                button.tab {
                    padding: 10px 20px;
                    color: black;
                    min-width: 70px;
                    text-align: center;
                    cursor: pointer;
                    background-color: transparent;
                    border: none;

                    &.active {
                        color: rgb(98, 104, 243);
                        border-bottom: 1px solid rgb(98, 104, 243);

                    }
                }


            }

            div.tabContent {
                width: 100%;
                height: 100%;
                height: -webkit-fill-available;
                padding: 5px 20px;
                overflow: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                    display: block;
                    background-color: rgb(214, 212, 212);

                }

                &::-webkit-scrollbar-thumb {
                    background-color: grey;
                    border-radius: 6px;
                    border: 3px solid grey;
                }

                .emptyContainer {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h1.emptyHeader {
                        font-size: 24px;
                        color: rgb(152, 150, 150);

                    }
                }


                .commentDetail {
                    width: 100%;
                    height: fit-content;
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;


                    .userInfo {
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                        .userImageContainer {

                            width: 60px;
                            height: 60px;

                            img.userImage {
                                border-radius: 100%;
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .info {
                            margin-left: 13px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .namedateContainer {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .name {
                                    margin: 0;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: black;
                                }

                                .date {
                                    margin: 0;
                                    margin-left: 15px;
                                    font-size: 13px;
                                    color: #707070;
                                    font-weight: 500;
                                }
                            }



                            .star {
                                margin: 0;
                            }

                            .email {
                                color: #707070;
                                margin: 0;
                            }

                            .postInfoContainer {
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                p.info {
                                    margin: 0;
                                    color: black;
                                    font-size: 15px;
                                    width: fit-content;
                                    margin-right: 20px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;

                                    svg {
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }

                    }

                    .userComment {
                        margin-top: 10px;
                        width: 100%;

                        p.commentText {
                            margin: 0;
                            padding-right: 15px;
                            font-size: 14px;
                            letter-spacing: 0px;
                            color: #707070;
                            text-align: left;
                            width: fit-content;

                            @media (max-width:1400px) {
                                font-size: 12px;
                            }

                            @media (max-height:700px) {
                                font-size: 12px;
                            }
                        }

                        div.updateButtons {
                            margin: 0;
                            margin-top: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            p.edit,
                            p.delete {
                                margin: 0;
                                padding-right: 15px;
                                font-size: 14px;
                                letter-spacing: 0px;
                                color: #707070;

                                @media (max-width:1400px) {
                                    font-size: 12px;
                                }

                                @media (max-height:700px) {
                                    font-size: 12px;
                                }
                            }

                            p.delete {
                                color: #6268F3;
                            }
                        }

                    }

                }


            }


        }
    }
}
.categoryManagement {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;


    .title {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;
        align-items: center;

        .contentTitle {
            color: black;
            margin: 0;
            margin-right: 10px;
        }

        button {
            margin-left: 20px;
            background-color: rgb(98, 104, 243);
            cursor: pointer;
            border: none;
            font-size: 15px;
            padding: 7px 25px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

        }

    }
}
.changePassword {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;

    .contentTitle {
        margin-bottom: 40px;
        color: black;
    }

    .content {
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        height: inherit;



        .row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 20px;

            h2 {
                color: white;
                margin-right: 40px;
                text-align: center;
                margin: 0 0 20px;
            }

            .inputFields {
                margin: 12px 0;
                padding: 0 30px;
                width: 100%;
                border-radius: 50px;
                background-color: white;
                height: 70px;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #474F61;

                input {
                    padding: 0 10px;

                }


                button {
                    border-radius: 100%;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    cursor: pointer;
                    border: none;
                    padding: 0;

                    svg {
                        font-size: 1.6rem;
                        color: black;
                        opacity: 0.6;
                    }
                }



                input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 10px 0 0;
                    color: black;
                    font-size: 16px;
                    border: none;
                    outline: none;
                }
            }


            .updateInput {
                width: 100%;
                display: flex;
                flex-direction: row;
                height: auto;
                min-height: 30px;
                padding: 15px 10px;
                background-color: white;
                border-radius: 20px;
                justify-content: center;
                align-items: center;


                .input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 30px;
                    color: black;
                    font-size: 16px;
                    border: none;
                    outline: none;
                    font-size: 1.2rem;

                    &::-webkit-inner-spin-button,
                    ::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                }
            }
        }

        .submit {
            width: 100%;
            border-radius: 50px;
            // background-image: linear-gradient(90deg, #BA2D52, #D93B30);
            background-color: #6268F3;
            border: none;
            color: white;
            font-size: 1.6rem;
            min-height: 70px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 350px;
            margin: auto;

            @media (max-height:800px) {
                margin: 70px auto;

            }

        }
    }


}
.businessManagement {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;


    .title {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;
        align-items: center;

        .contentTitle {
            color: black;
            margin: 0;
            margin-right: 10px;
        }

    }

    .content {
        width: 100%;
        height: 100%;
        overflow: auto;

        .row {
            padding: 0 4px;

            .card {
                border-radius: 10px;
                padding: 5px;
                height: auto;
                background-color: white;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                border: 1px solid #474F61;



                img.cardImage {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 4/2;
                    border-radius: 10px;
                }

                .cardDetails {
                    padding: 5px;

                    p.cardTitle {
                        margin: 0;
                        color: rgb(98, 104, 243);
                        font-size: 16px;
                    }

                    p.description {
                        margin: 5px 0 20px;
                        color: grey;
                        font-size: 13px;

                        @media (min-width : 1200px)and (max-width : 1300px) {
                            font-size: 12px;
                        }
                    }



                    button.view {
                        width: 100%;
                        border-radius: 20px;
                        background-color: rgb(98, 104, 243);
                        color: white;
                        border: none;
                        padding: 5px;
                        font-size: 13px;
                    }

                }
            }
        }

    }
}
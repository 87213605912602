.termsAndCondition {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;

    .title {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;
        align-items: center;

        .contentTitle {
            color: black;
            margin: 0;
        }

        button {
            margin-left: 10px;
            background-color: transparent;
            border-radius: 100%;
            margin-top: 10px;
            cursor: pointer;
            border: none;

            svg {
                font-size: 1.5rem;
                color: black;

            }
        }

    }



    .content {
        overflow: overlay;
        width: 100%;
        height: inherit;
        display: flex;
        flex-direction: column;
        padding: 0 10px;

        p {
            font-size: 1rem;
        }

        textarea {
            background-color: transparent;
            color: black;
            height: inherit;
            resize: none;

            &:focus-visible {
                outline: none;
            }
        }
    }
}
.table {
    overflow: auto;
    border-radius: 5px;


}


.table::-webkit-scrollbar {
    width: 0;
    height: 5px;
    display: block;
    background-color: rgb(214, 212, 212);

}

.table::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 6px;
    border: 3px solid grey;
}


.table .ant-table {
    background: transparent;
}



.table thead tr th {

    /* background-image: linear-gradient(180deg, #202339, #222741); */
    background-color: #474F61;
    border: none;
    overflow: hidden;
    color: white;
    font-size: 1rem;
}

.table .row {
    background-color: white;
    color: #141414;
    margin: 2px 0;
    border-bottom: none;
    margin: 10px 0;
    border: none;

}


.table .row.cursor {
    cursor: pointer;
}

.table .row:hover {
    background-color: #f7f7fa;
}

.table tbody tr td.ant-table-cell {
    background-color: white;
    border-top: 5px solid #f7f7fa;
    border-bottom: none;
}

.table tbody tr td .ant-empty-description {
    color: #adabab;
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background-color: white;

}

.table .row td {
    border-top: 5px solid #0A0A0A;
    border-bottom: none;
    background: transparent !important;
}

.table thead .row td:hover {
    background: transparent !important;
}


.pagination li {
    background-color: #f7f7fa;
    border: none;
}

.pagination li a {
    color: gray;

}

.pagination li a:hover {
    color: gray;
}

.pagination li.ant-pagination-prev button,
.pagination li.ant-pagination-next button {
    background-color: #f7f7fa;
    border: none;
    color: black;
}

.pagination li.ant-pagination-item-active {
    /* background-image: linear-gradient(180deg, #202339, #222741); */
    color: white;
    background-color: #474F61;
}

.pagination li.ant-pagination-item-active a {
    color: white;
}
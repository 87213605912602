.userManagement {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;


    .contentTitle {
        margin-bottom: 40px;
        color: black;
    }
}
.main {
    min-height: 100vh;
    height: 100vh;
    /* height: auto; */
    margin: 0;
    /* display: flex; */
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .layout {
        width: 100%;
        /* height: auto; */
        min-height: 100vh;
        margin: 0;
        background-image: url("../../../public/images/background.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: transparent;
        display: flex;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }


        .content {
            padding: 4% 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: -moz-fit-content;
            height: inherit;
            width: 100%;
            min-height: 100vh;
            position: relative;


            img {
                position: relative;
                top: 0;
                width: 10vw;
                height: 20vh;
                user-select: none;
            }

            .form {
                margin: 70px 0;
                color: white;
                display: flex;
                flex-direction: column;
                user-select: none;
                height: -webkit-fill-available;


                h1 {
                    margin: 0;
                    text-align: center;
                    font: normal normal normal Gilroy-SemiBold;
                    font-size: 2rem;
                    font-weight: 600;
                    color: white;
                }

                p {
                    text-align: center;
                    margin-top: 20px;
                    opacity: 0.6;
                }

                .fields {
                    height: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin: auto;
                    width: 350px;

                    .inputFields {
                        margin: 12px 0;

                        width: 100%;
                        border-radius: 50px;
                        background-color: white;
                        height: 70px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;


                        input {
                            width: 100%;
                            height: 100%;

                            background-color: transparent;
                            color: black;
                            font-size: 16px;
                            border: none;
                            outline: none;
                        }

                        input[type="text"] {

                            padding: 0 30px;
                        }

                        input[type="password"] {

                            padding: 0 0 0 30px;
                        }



                        button {
                            border-radius: 100%;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: transparent;
                            cursor: pointer;
                            border: none;
                            padding: 0;

                            svg {
                                margin: 0 30px 0 10px;
                                font-size: 1.6rem;
                                color: black;
                                opacity: 0.6;
                            }
                        }


                    }
                }

                .submit {
                    width: 100%;
                    border-radius: 50px;
                    // background-image: linear-gradient(90deg, #BA2D52, #D93B30);
                    background-color: transparent;
                    border: 1px solid white;
                    color: white;
                    font-size: 1.6rem;
                    height: 120px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 350px;
                    margin: auto;
                }

            }

            .switch {
                position: relative;
                bottom: 0;
                font-size: 1rem;
                color: white;
                margin: 0;
                font: normal normal normal Gilroy-Medium;
                font-size: 20px;
                cursor: pointer;
                user-select: none;

            }
        }

    }
}
.modal {
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;

    .outside {
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }


    .newFaq {

        width: 400px;
        display: flex;
        flex-direction: column;
        padding: 30px;
        background-color: white;
        border-radius: 20px;

        .headerContainer {
            display: flex;
            flex-direction: row;
            align-items: center;

            h1 {
                font-size: 19px;
                color: black;
                margin: 0;
            }

            .cancel {
                text-align: right;
                margin-left: auto;

                button {
                    cursor: pointer;
                    border-radius: 100%;
                    border: none;
                    padding: 5px 12px;
                }
            }
        }

        input {
            background-color: transparent;
            color: black;
            height: inherit;
            resize: none;
            padding: 10px 20px;
            margin: 40px 0;
            border: 1px solid #b8b9bc;
            border-radius: 20px;
            width: 100%;

            &:focus-visible {
                outline: none;
            }
        }

        .save {
            text-align: center;

            button {
                margin: auto;
                background-color: rgb(98, 104, 243);
                color: white;
                cursor: pointer;
                border: none;
                font-size: 15px;
                padding: 7px 50px;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;


            }
        }


    }
}